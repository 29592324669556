* {
  box-sizing: border-box;
}

.App-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1220px;
  max-width: 70%;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
}

.App-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form .col {
  flex-basis: 48%;
  max-width: 48%;
  box-sizing: border-box;
}

.form button[type=submit] {
  width: 100%;
  padding: 15px 15px;
  background-color: #faa61a;
  border: 1px solid #faa61a;
  font-size: 18px;
  font-weight: 400;
}


.upload-button {
  margin-bottom: 10px;
}

.upload-file-button {
  padding: 10px 15px;
  background: #fde1b3;
  border: 1px solid #fbb033;
  color: #000;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 23px;
  width: 100%;
}

.upload-button .preview {
  display: none;
  margin-top: 10px;
  width: 100%;
  height: auto;
}

.upload-button.show-preview .preview {
  display: block;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-field label {
  margin-bottom: 5px;
}

.form-field input {
  padding: 10px 15px;
  width: 100%;
}

.intro {
  padding: 20px;
  background: #eee;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.intro h2 {
  margin-top: 0;
  font-size: 20px;
}

.intro p {
  font-size: 14px;
}

.intro p:last-of-type {
  margin-bottom: 0;
}

.generate-section {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.generate-section button {
  align-self: baseline;
  background-color: rgb(249, 55, 159);
  padding: 10px 20px;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  width: 250px;
}

.generate-section textarea {
  display: none;
  padding: 10px;
}